export default {
  available_vehicles: 'Tilgængelige køretøjer',
  currency: 'DKK',
  currency_monthly: 'Måned',
  popup_close: 'Tæt',
  navigate_back: 'Tilbage',
  vehicle_list_no_matches: 'Desværre har vi ikke nogen motorcykler, der lever op til din specifikation.',
  vehicle_list_other_vehicles: 'Andre motorcykler på lager',
  vehicle_list_sort_by: 'Sorter efter',
  vehicle_list_sort_by_price: 'Pris',
  filter_models: 'Modeller',
  filter_location: 'Forhandler',
  filter_location_title: 'Find en forhandler',
  filter_location_search_radius: 'Søg inden for et bestemt område, eller vælg en forhandler nedenfor',
  filter_location_search_distance: 'Afstand',
  filter_reset: 'Nulstil filter',
  filter_price: 'Pris',
  filter_color: 'Farve',
  filter_availability: 'Tilgængelighed',
  filter_power: 'Effekt',
  filter_torque: 'Moment',
  filter_applied_models: 'Modeller',
  filter_applied_locations: 'Steder',
  filter_search_for_equipment: 'Søg efter udstyr',
  vehicle_details_engine_output: 'Effekt',
  vehicle_details_cylinder_capacity: 'Cylindervolumen',
  vehicle_details_vehicle_identifier: 'ID',
  vehicle_details_360_View: '360° udvendigt',
  vehicle_details_overview: 'Oversigt',
  vehicle_details_gallery: 'Billedgalleri',
  vehicle_details_video: 'Video',
  vehicle_details_equipment: 'Udstyr',
  vehicle_details_accessories: 'Tilbehør',
  vehicle_details_total_price: 'Samlet pris',
  vehicle_details_financing_from: '',
  vehicle_details_open_calculator: '',
  vehicle_details_request_offer: 'Kontakt mig',
  financial_calc_downpayment: '',
  financial_calc_period: '',
  financial_calc_remaining: '',
  financial_calc_monthly_payment: '',
  financial_calc_text:
    'Dette er den samlede pris for netop denne motorcykel. Prisen er fastsat af forhandleren og prisen kan ændres.',
  financial_calc_text1: '',
  book_first_name: 'Fornavn',
  book_last_name: 'Efternavn',
  book_phone: 'Mobilnummer',
  book_email: 'E-mail',
  book_enter_required_fields: 'Alle felter skal udfyldes',
  book_marketing_title: 'Marketingsamtykke.',
  book_marketing_checkbox1_text:
    'Jeg vil gerne modtage markedsføringsmateriale fra BMW pr. e-mail, post, telefon/beskedtjenester (messenger) og i bilen (hvis understøttet).',
  book_marketing_checkbox1_details:
    'Jeg erklærer mig indforstået med, at BMW Danmark (BMW Danmark A/S, Borgmester Christiansens Gade 50, DK-2450 København SV) behandler og anvender de personoplysninger, som jeg har opgivet (fx kontaktoplysninger, personlige oplysninger, hobbyer og interesser) i forbindelse med kundepleje og i forbindelse med særlig kommunikation af BMW Groups produkt- og serviceoplysninger samt i forbindelse med markedsundersøgelser. BMW Danmark kan ligeledes videregive personoplysninger til visse beslægtede selskaber samt visse aftalepartnere og workshops (min foretrukne forhandler, samarbejdspartnere i forbindelse med mine bilkøb, service- eller inspektionsbesøg eller min regionale samarbejdspartner), som kan anvende mine oplysninger og kontakte mig i forbindelse med ovennævnte formål. Opdateringer kan deles mellem de anførte selskaber.',
  book_marketing_checkbox2_text:
    'Jeg ønsker desuden at modtage personaliserede produkt- og servicetilbud, som er tilpasset mig på baggrund af mine personlige præferencer og adfærdsmønstre samt ud fra min brug af produkter og ydelser.',
  book_marketing_checkbox2_details:
    'Jeg erklærer mig indforstået med, at mine personoplysninger anvendes til at oprette en individuel kundeprofil ved hjælp af statistiske oplysninger. Jeg vil på baggrund af denne profil modtage markedsføringsmateriale med et indhold, som er relevant for mig, såsom fx personligt tilpassede tilbud fra BMW Danmark og visse beslægtede selskaber samt visse aftalepartnere og workshops (min foretrukne forhandler, samarbejdspartnere i forbindelse med mine bilkøb, service- eller inspektionsbesøg eller min regionale samarbejdspartner). Jeg erklærer mig ligeledes indforstået med, at kundeprofilen videregives fra BMW Danmark til visse beslægtede selskaber samt mine aftalepartnere og workshops i overensstemmelse med de ovennævnte formål, og at disse selskaber må kontakte mig med materiale, der er relevant for mig. Følgende oplysninger, der er afgivet af mig eller genereret på baggrund af min brug af produkter eller ydelser fra BMW Danmark, visse beslægtede selskaber samt deres aftalepartnere og workshops, må i relevant omfang indgå i profiludviklingen: kontaktoplysninger (såsom fx navn, adresse, e-mailadresse); supplerende oplysninger/præferencer (såsom fx foretrukken forhandler, hobbyer); identifikationsoplysninger (såsom fx kundenummer, kontraktnummer); kundehistorik (såsom fx modtagelse af tilbud, oplysninger vedr. bilkøb, oplysninger om forhandler); oplysninger vedr. køretøjet (såsom fx oplysninger om brug af My BMW-applikationen: kilometerstand, kørsel på fuld tank (crusing range); oplysninger vedr. brug af app/hjemmeside/sociale medier. En udtømmende liste over oplysninger inden for disse kategorier kan findes her.',
  book_policy: 'Ændringsanmodninger vedrørende samtykkeerklæringen og ret til at tilbagekalde samtykke.',
  book_policy_details:
    "Jeg kan til enhver tid tilbagekalde mine til BMW Danmark afgivne samtykker med virkning for fremtiden ved at kontakte BMW's kundeservice 70 156 156 eller nordic.dataprivacy@bmw.dk. Efter tilbagetrækning af mit samtykke, vil mine kundeoplysninger ikke længere blive behandlet, og jeg vil ikke modtagere yderligere markedsføringsmateriale. Gennem de anførte kommunikationskanaler kan jeg ligeledes anmode om at få at vide, hvilke personoplysninger BMW Danmark opbevarer om mig, samt anmode om at mine personoplysninger rettes, slettes eller blokeres. Yderligere oplysninger vedrørende BMW Danmarks behandling af mine personoplysninger samt mine rettigheder kan findes online under BMW's privatlivspolitik.",
  book_submit: 'Kontakt mig',
  confirm_title: 'Tak for din forespørgsel',
  confirm_subtitle: 'Du vil snart blive kontaktet af en forhandler.',
  availability_IN_STOCK: 'På lager',
  availability_ON_THE_WAY: 'På vej',
  view_more: 'Vis mere',
  book_a_test_ride: 'Book a test ride',
  get_an_offer: 'Kontakt forhandleren',
  get_an_offer_button: 'Send en forespørgsel',
  technical_data: 'Technical data',
  technical_data_Power: 'Power',
  technical_data_Cylinder: 'Cylinder',
  'technical_data_Top speed': 'Top speed',
  technical_data_Torque: 'Torque',
  'technical_data_Drive Type': 'Drive Type',
  technical_data_Transmission: 'Transmission',
  'technical_data_Seat height': 'Seat height',
  'technical_data_Seat dimensions': 'Seat dimensions',
  'technical_data_Tank capacity': 'Tank capacity',
  'technical_data_Rear tire size': 'Rear tire size',
  'technical_data_Front wheel size': 'Front wheel size',
  'technical_data_Rear wheel size': 'Rear wheel size',
  vehicle_details_select_financing_option: 'Kontakt forhandleren',
  vehicle_details_add_contact_info: 'Add contact info',
  vehicle_details_hide_financing_params: 'Hide parameters',
  vehicle_details_adjust_financing_params: 'Adjust parameters',
  book_field_required: 'Required',
  request_test_ride: 'Request test ride',
  color_GRAY: 'Grå',
  color_WHITE: 'Hvid',
  color_BLACK: 'Sort',
  color_RED: 'Rød',
  color_BLUE: 'Blå',
  'promotion_Test ride your dream BMW': 'Test ride your dream BMW',
  'promotion_Build your ultimate BMW bike': 'Build your ultimate BMW bike',
  'promotion_Get the best deal': 'Get the best deal',
  'promotion_Trade in your old bike': 'Trade in your old bike',
  'promotion_Get the latest news': 'Get the latest news',
  'promotion_Lots of questions?': 'Lots of questions?',
  'promotion_action_Book a test ride': 'Book a test ride',
  promotion_action_Configure: 'Configure',
  'promotion_action_Request for offer': 'Request for offer',
  'promotion_action_Trade-in offer': 'Trade-in offer',
  promotion_action_Subscribe: 'Subscribe',
  'promotion_action_Get contacted': 'Get contacted',
  total_price_disclaimer:
    'Dette er den aktuelle pris for dette køretøj. Den endelige salgspris fastsættes af forhandleren og kan ændres uden varsel. Billedet er kun en illustration og kan afvige fra det faktiske køretøj på lager.',
  show_filters: 'Vis filtrene',
  offer_success_message: 'Din forespørgsel på et tilbud er sendt. Vores team vil kontakte dig hurtigst muligt.',
  'package_Other equipment': 'Andet udstyr',
  no_results_found: 'Ingen resultater blev fundet',
  book_message: 'Besked',
  map: 'Kort',
  list: 'Liste',
  search_within_specific_area: 'Søg inden for et specifikt område eller vælg en forhandler nedenfor',
  filter_distance: 'Afstand',
  enter_location: 'Angiv en placering',
  'Your filter selection did not return any matches.': 'Dit filtervalg gav ingen resultater.',
  'Please modify your search criteria or': 'Du kan ændre dine søgekriterier eller ',
  'reset filters': 'nulstil filtrene',
  'to start again': ' for at starte forfra.',
  location: 'Placering',
  card_special_price: 'Kampagnepris',
  card_price: 'Samlet pris',
};
