export default {
  available_vehicles: 'Saatavilla olevat ajoneuvot',
  currency: 'EUR',
  currency_monthly: 'Kuukausi',
  popup_close: 'Kiinni',
  navigate_back: 'Takaisin',
  vehicle_list_no_matches: 'Valitettavasti meillä ei ole moottoripyöriä, jotka täyttävät määrittelyt.',
  vehicle_list_other_vehicles: 'Muut moottoripyörät varastossa',
  vehicle_list_sort_by: 'Lajittele',
  vehicle_list_sort_by_price: 'Hinta',
  filter_models: 'Mallit',
  filter_location: 'Jälleenmyyjä',
  filter_location_title: 'Etsi jälleenmyyjä',
  filter_location_search_radius: 'Hae tietyllä alueella tai valitse jälleenmyyjä alta',
  filter_location_search_distance: 'Etäisyys',
  filter_reset: 'Nollaa suodatin',
  filter_price: 'Hinta',
  filter_availability: 'Saatavuus',
  filter_color: 'Väri',
  filter_power: 'Teho',
  filter_torque: 'Vääntömomentti',
  filter_applied_models: 'Mallit',
  filter_applied_locations: 'Toimipiste',
  filter_search_for_equipment: 'Etsi varusteita',
  vehicle_details_engine_output: 'Teho',
  vehicle_details_cylinder_capacity: 'Moottorin tilavuus',
  vehicle_details_vehicle_identifier: 'ID',
  vehicle_details_360_View: '360 Yleiskatsaus',
  vehicle_details_overview: 'Yleiskatsaus',
  vehicle_details_gallery: 'Kuvagalleria',
  vehicle_details_video: 'Video',
  vehicle_details_equipment: 'Varustetiedot',
  vehicle_details_accessories: 'Lisätarvikkeet',
  vehicle_details_total_price: 'Kokonaishinta',
  vehicle_details_financing_from: '',
  vehicle_details_open_calculator: '',
  vehicle_details_request_offer: 'Ota yhteyttä minuun',
  financial_calc_downpayment: '',
  financial_calc_period: '',
  financial_calc_remaining: '',
  financial_calc_monthly_payment: '',
  financial_calc_text:
    'Vastuuvapauslauseke: Tämä on tämän moottoripyörä nykyinen hinta. Hinnan määrittää jälleenmyyjä varastossa olevalle autolle ja se voi muuttua.',
  financial_calc_text1: '',
  book_first_name: 'Etunimi',
  book_last_name: 'Sukunimi',
  book_phone: 'Puhelin',
  book_email: 'Sähköpostiosoite',
  book_enter_required_fields: 'Kaikki kentät ovat pakollisia',
  book_marketing_title: 'Hyväksyn markkinointiehdot.',
  book_marketing_checkbox1_text:
    'Haluan, että BMW lähettää minulle markkinointiviestintää sähköpostitse, postitse, puhelimitse/viestipalveluiden kautta ja suoraan autooni (jos mahdollista).',
  book_marketing_checkbox1_details:
    'Suostut siihen, että BMW Suomi (Oy BMW Suomi Ab, Äyritie 8c, 01510 VANTAA) käsittelee ja käyttää antamiasi henkilötietoja (esimerkiksi yhteystietoja ja henkilökohtaisia tietoja, kuten syntymäaikaa, harrastuksia ja kiinnostuksen kohteita) asiakaspalveluun, markkinatutkimukseen ja BMW-konsernin tuotteista ja palveluista tiedottamiseen. BMW Suomi voi lisäksi siirtää näitä tietoja yhtiön tytäryhtiöille sekä eräille sopimuskumppaneille ja huoltoliikkeille (ilmoittamallesi ensisijaiselle jälleenmyyjälle, autokauppoihisi liittyville yhteistyökumppaneille, palveluntarjoajille, konsulteille tai alueesi yhteistyökumppaneille), jotka voivat käyttää tietojasi ja ottaa sinuun yhteyttä yllä mainituissa tarkoituksissa. Nämä tytäryhtiöt voivat luovuttaa tietojen päivityksiä toisilleen.',
  book_marketing_checkbox2_text:
    'Haluan lisäksi, että minulle lähetetään räätälöityjä BMW-konsernin tuote- ja palvelutarjouksia BMW Suomen tunnistamien henkilökohtaisten mieltymysteni ja käyttötottumusteni sekä käyttämieni tuotteiden ja palveluiden perusteella.',
  book_marketing_checkbox2_details:
    'Suostut siihen, että henkilötietoja käytetään yksilöllisen asiakasprofiilin luomiseen tilastollisin menetelmin. Asiakasprofiilin perusteella sinulle lähetetään räätälöityä markkinointiviestintää, kuten henkilökohtaisia tarjouksia BMW Suomelta ja yhtiön tytäryhtiöiltä sekä eräiltä sopimuskumppaneilta ja huoltoliikkeiltä (ilmoittamaltasi ensisijaiselta jälleenmyyjältä, autokauppoihisi liittyviltä yhteistyökumppaneilta, palveluntarjoajilta, konsulteilta tai alueesi yhteistyökumppaneilta). Suostut lisäksi siihen, että asiakasprofiilisi välitetään BMW Suomelta eräille tytäryhtiöille, sopimuskumppaneillesi ja huoltoliikkeille yllä mainittuihin tarkoituksiin ja että nämä yhtiöt voivat lähettää sinulle räätälöityä sisältöä. Profiilin kehittämiseen voidaan käyttää seuraavia tietoja, jotka luovutat itse tai jotka syntyvät käyttäessäsi BMW Suomen, eräiden tytäryhtiöiden ja niiden sopimuskumppaneiden ja huoltoliikkeiden tuotteita tai palveluita, mikäli tietoja on saatavilla: yhteystiedot (esimerkiksi nimi, osoite, sähköpostiosoite), lisätiedot/asetukset (esimerkiksi ensisijainen jälleenmyyjä, harrastukset), tunnistetiedot (esimerkiksi asiakasnumero, sopimusnumero), asiakashistoria (esimerkiksi saamasi tarjoukset, tiedot ajoneuvokaupoista, jälleenmyyjätiedot), ajoneuvotiedot (esimerkiksi My BMW-sovelluksen käyttötiedot: ajokilometrit, toimintasäde), sovellusten/verkkosivujen/sosiaalisen median tiedot. Kattava luettelo näihin ryhmiin kuuluvista tiedoista on tällä verkkosivulla.',
  book_policy: 'Suostumuksen muuttaminen ja peruutusoikeus.',
  book_policy_details:
    'Voit milloin tahansa peruuttaa BMW Suomelle antamasi suostumuksen ottamalla yhteyttä BMW:n asiakaspalveluun puhelimitse 020-7345 920 tai sähköpostitse nordic.dataprivacy@bmw.fi, jolloin peruutus tulee voimaan peruutushetkestä alkaen. Suostumuksen peruuttamisen jälkeen profiiliasi ei enää käsitellä eikä sinulle lähetetä markkinointiviestintää. Näitä viestintäkanavia käyttämällä voit myös pyytää tietoja BMW Suomen säilyttämistä sinua koskevista tiedoista ja pyytää henkilötietojesi oikaisua tai korjausta tai niiden käytön estoa. Lisätietoja BMW Suomen suorittamasta henkilötietojen käsittelystä ja sinulle kuuluvista oikeuksista on verkossa BMW:n tietosuojaperiaatteissa.',
  book_submit: 'Ota yhteyttä minuun',
  confirm_title: 'Kiitos tiedustelustasi',
  confirm_subtitle: 'Jälleenmyyjä ottaa sinuun pian yhteyttä.',
  availability_IN_STOCK: 'Varastossa',
  availability_ON_THE_WAY: 'Matkalla',
  view_more: 'Näytä lisää',
  book_a_test_ride: 'Varaa koeajo',
  get_an_offer: 'Ota yhteyttä jälleenmyyjään',
  get_an_offer_button: 'Lähetä kysely',
  technical_data: 'Tekniset tiedot',
  technical_data_Power: 'Teho',
  technical_data_Cylinder: 'Cylinder',
  'technical_data_Top speed': 'Top speed',
  technical_data_Torque: 'Vääntömomentti',
  'technical_data_Drive Type': 'Drive Type',
  technical_data_Transmission: 'Transmission',
  'technical_data_Seat height': 'Seat height',
  'technical_data_Seat dimensions': 'Seat dimensions',
  'technical_data_Tank capacity': 'Tank capacity',
  'technical_data_Rear tire size': 'Rear tire size',
  'technical_data_Front wheel size': 'Front wheel size',
  'technical_data_Rear wheel size': 'Rear wheel size',
  vehicle_details_select_financing_option: 'Ota yhteyttä jälleenmyyjään',
  vehicle_details_add_contact_info: 'Add contact info',
  vehicle_details_hide_financing_params: 'Hide parameters',
  vehicle_details_adjust_financing_params: 'Adjust parameters',
  book_field_required: 'Required',
  request_test_ride: 'Request test ride',
  color_GRAY: 'Harmaa',
  color_WHITE: 'Valkoinen',
  color_BLACK: 'Musta',
  color_RED: 'Punainen',
  color_BLUE: 'Sininen',
  'promotion_Test ride your dream BMW': 'Test ride your dream BMW',
  'promotion_Build your ultimate BMW bike': 'Build your ultimate BMW bike',
  'promotion_Get the best deal': 'Get the best deal',
  'promotion_Trade in your old bike': 'Trade in your old bike',
  'promotion_Get the latest news': 'Get the latest news',
  'promotion_Lots of questions?': 'Lots of questions?',
  'promotion_action_Book a test ride': 'Book a test ride',
  promotion_action_Configure: 'Configure',
  'promotion_action_Request for offer': 'Request for offer',
  'promotion_action_Trade-in offer': 'Trade-in offer',
  promotion_action_Subscribe: 'Subscribe',
  'promotion_action_Get contacted': 'Get contacted',
  total_price_disclaimer:
    'Tämä on kyseisen ajoneuvon nykyinen hinta. Hinnan määrittää varastossa olevaa ajoneuvoa hallinnoiva jälleenmyyjä, ja se voi muuttua ilman erillistä ilmoitusta. Kuva on tarkoitettu vain havainnollistamiseen ja saattaa poiketa varastossa olevasta ajoneuvosta.',
  show_filters: 'Näytä suodattimet',
  offer_success_message: 'Tarjouspyyntö lähetetty onnistuneesti. Tiimimme ottaa sinuun yhteyttä mahdollisimman pian.',
  'package_Other equipment': 'Muut varusteet',
  no_results_found: 'Tuloksia ei löytynyt',
  book_message: 'Viesti',
  map: 'Kartta',
  list: 'Lista',
  search_within_specific_area: 'Etsi tietyltä alueelta tai valitse jälleenmyyjä alla',
  filter_distance: 'Etäisyys',
  enter_location: 'Syötä sijainti',
  'Your filter selection did not return any matches.': 'Suodatusvalintasi eivät tuottaneet tuloksia.',
  'Please modify your search criteria or': 'Ole hyvä ja muuta hakukriteerejäsi tai',
  'reset filters': 'nollaa suodattimet',
  'to start again': ' aloittaaksesi uudelleen.',
  card_special_price: 'Kampanjahinta',
  card_price: 'Kokonaishinta',
};
